<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-4">
                <!-- <form action="#"> -->
                    <div class="input-group">
                        <input type="search" class="form-control form-control" placeholder="Cari Kelas">
                        <div class="input-group-append">
                            <button type="submit" class="btn btn btn-default">
                                <i class="fa fa-search"></i>
                            </button>
                        </div>
                    </div>
                <!-- </form> -->
                </div>
            </div>
            <br>
            <div  class="row">
                <div v-for="row in optSiswa" v-bind:key="row.thread_id" class="col-md-4 col-sm-8 col-12">
                <router-link :to="{
                name: 'Detail Diskusi',
                params: { filter: { id: row.thread_id, kelas_id: row.kelas_ids, nama_siswa: row.siswa_name, kelas: row.kelas}},}">
                    <div class="info-box shadow-sm">
                    <span class="info-box-icon bg-info" v-if="row.t_last_open > row.t_created_on"><i class="far fa-comments fa-1x my-2 mx-1" style="font-size: 50px;"></i></span>
                    <span class="info-box-icon bg-info" v-if="row.t_last_open < row.t_created_on"><i class="fas fa-comments fa-1x my-2 mx-1" style="font-size: 50px;"></i></span>
                    <div class="info-box-content">
                        <span class="info-box-text" v-if="row.t_last_open > row.t_created_on">{{row.siswa_name}}&nbsp;<i class="fas fa-check-double float-right" style="font-size: 18px; color: #39A2DB;"></i></span>
                        <span class="info-box-text" v-if="!row.t_last_open || row.t_last_open < row.t_created_on">{{row.siswa_name}}&nbsp;<i class="fas fa-circle float-right" style="font-size: 18px; color: red;"></i></span>
                        <span class="info-box-text">Kelas: {{row.kelas}}</span>
                        <span class="info-box-text">Tanggal dibuat: {{formatDate(row.thread_created_on)}}</span>
                        <span class="info-box-number">{{row.thread_num}} Pertanyaan</span>
                    </div>
                    </div>
                </router-link>
                </div>
                <div class="col-md-4 col-sm-8 col-12">
                <h4 v-if="optSiswa.length < 1">TIDAK ADA CHAT.</h4>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { auth, authFetch } from "@/libs/hxcore";
import moment from "moment";

export default {
    name: 'DiskusiFilter',    
    data: () => ({
        optSiswa: [],
        id: ''
    }),
    created: function () {
        let id = this.$route.params.id;
        this.id = this.$route.params.id;
        sessionStorage.filterData='';
        sessionStorage.filterStatus = '';
        this.loadSiswa(id);
    },
    methods: {
        formatDate(data){
            return moment(data).format("DD/MM/YYYY");
        },
        loadSiswa(id=''){
            this.optSiswa = [];
            authFetch("/tentor/tanya_guru/datadiskusi/"+this.id).then((res) => {
                res.json().then((json) => {
                    if (json.success) {
                        this.optSiswa = json.data;
                    }
                });
            });
        }
    },
    mounted(){
        this.loadSiswa();
    },
}
</script>
<style type="text/css" scoped>
</style>